<template>
  <div>
    <v-select
      v-if="type === 'dropdown'"
      v-model="obj[value]"
      :items="dropdownValues.values"
      :item-text="dropdownValues.text ? dropdownValues.text : ''"
      :item-value="dropdownValues.value ? dropdownValues.value : ''"
      single-line
      dense
      solo
      background-color="#20212E"
      hide-details
      class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
      :readonly="flag"
      :append-icon="flag ? '' : '$dropdown'"
    >
    </v-select>
    <v-text-field
      v-else-if="type === 'textfield'"
      v-model="obj[value]"
      type="text"
      outlined
      dense
      single-line
      hide-details
      :disabled="flag"
      background-color="#20212E"
    >
    </v-text-field>
    <v-checkbox
      v-else-if="type === 'checkbox'"
      class="pa-0 ma-auto checkbox dark_blue"
      hide-details
      open-on-click
      dense
      v-model="obj[value]"
      :value="obj[value]"
      :false-value="checkboxValues.falseValue"
      :true-value="checkboxValues.trueValue"
    ></v-checkbox>
    <DatePicker
      v-else-if="type === 'datepicker'"
      v-model="obj[value]"
      :data="obj"
      :keyData="value"
      :existingModel="obj[value]"
      :hideDetails="true"
    ></DatePicker>
    <template v-else>
      {{ obj[value] }}
    </template>
  </div>
</template>

<script>
import DatePicker from "@/components/General/DatePickerGeneral.vue";

export default {
  props: [
    "field",
    "value",
    "type",
    "dropdownValues",
    "obj",
    "flag",
    "checkboxValues",
  ],
  components: {
    DatePicker,
  },
};
</script>

<style lang="scss" scoped></style>
