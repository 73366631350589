<template>
  <v-data-table
    class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="headerTable"
    :items="items"
    width="100%"
    item-class="text-center"
    disable-pagination
    :sort-by="sortBy"
    :sort-desc="sortDesc"
  >
    <template v-slot:item="{ item, index }">
      <tr @click="$emit('clickRow', item)">
        <td v-for="header in headerTable" :key="header.value">
          <slot
            name="actions"
            class=""
            v-if="header.value === 'actions'"
            :index="index"
            :item="item"
          ></slot>

          <template v-if="header.value !== 'actions'">
            <v-text-field
              v-if="item.edit"
              outlined
              class="ma-0 rounded-0 non-outlined"
              full-width
              hide-details
              dense
              :dark="true"
              :background-color="
                !!item.disable && item.disable === header.value
                  ? 'dark_blue'
                  : '#303030'
              "
              v-model="item[header.value]"
              :disabled="!!item.disable && item.disable === header.value"
            >
            </v-text-field>
            <span class="ma-0 rounded-0" v-else>
              {{
                header.value === "date" || header.value === "createdDate"
                  ? parseDate(item[header.value])
                  : item[header.value]
              }}
            </span>
          </template>
        </td>
      </tr>
    </template>

    <template v-slot:[`body.append`]="{ headers }">
      <slot name="append" :headers="headers"> </slot>
    </template>
  </v-data-table>
</template>

<script>
import { parseDate } from "@/helpers/mocks/dates.js";

export default {
  props: ["items", "headerTable", "disableValues", "sortBy", "sortDesc"],
  methods: {
    parseDate,
  },
};
</script>

<style></style>
