<template>
  <v-data-table
    class="elevation-5 ma-0 rounded-0 main-table"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="headerTable"
    :items="items"
    :loading="loading"
    disable-pagination
  >
    <template v-slot:item="{ item, index }">
      <tr>
        <td v-for="header in headerTable" :key="header.value">
          <slot :index="index" :header="header" :item="item"></slot>
          <!-- <slot
            name="actions"
            class=""
            v-if="header.value === 'actions'"
            :index="index"
          ></slot>

          <template v-if="header.value !== 'actions'">
            <v-text-field
              v-if="item.edit"
              outlined
              class="ma-0 rounded-0 non-outlined"
              full-width
              hide-details
              dense
              color="#0000"
              :dark="true"
              background-color="#303030"
              v-model="item[header.value]"
            >
            </v-text-field>
            <span class="ma-0 rounded-0" v-else>
              {{ item[header.value] }}
            </span>
          </template> -->
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["items", "headerTable", "loading", "fixedHeader"],
};
</script>

<style></style>
