<template>
  <v-app-bar
    app
    color="primary"
    elevation="0"
    absolute
    dense
    class="main-navbar"
  >
    <!-- <a v-if="toggleSideBarMain == true" @click="handleOpenSideBar">
      <h1 style="color: white;">N</h1>
    </a> -->
    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          data-cy="nav-top-righ-acc"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="menuAction(item.action)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <snackbar :nav="true" />
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import Snackbar from "@/components/General/Snackbar";
export default {
  data: () => ({
    items: [
      {
        icon: "mdi-logout",
        text: "Logout",
        action: "logout",
      },
    ],
  }),
  components: {
    Snackbar,
  },
  computed: {
    ...mapState(["toggleSideBarMain"]),
  },
  methods: {
    handleOpenSideBar() {
      this.$store.commit("setToggleSideBarMain", true);
    },
    menuAction(action) {
      switch (action) {
        case "logout":
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          localStorage.removeItem("header");
          this.$store.commit("set_user", {});
          this.$router.push("/login");
          break;
      }
    },
  },
};
</script>

<style>
.main-navbar {
  z-index: 9999 !important;
}
</style>
