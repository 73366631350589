<template>
  <v-data-table
    class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
    dense
    fixed-header
    :headers="headerTable"
    :items="items"
    hide-default-footer
    disable-pagination
    height="100%"
    width="100%"
  >
    <template
      v-for="{ value, edit, noNew } of headerTable"
      v-slot:[`item.${value}`]="{ item }"
    >
      <div v-if="item.new" :key="value">
        <slot :name="`slot_${value}`" :item="item" />
        <div v-if="!$scopedSlots[`slot_${value}`]">
          <v-text-field
            v-if="!noNew"
            outlined
            class="ma-0 rounded-0"
            full-width
            hide-details
            dense
            :dark="true"
            background-color="#20212E"
            v-model="item[value]"
          >
          </v-text-field>
        </div>
      </div>
      <div v-else-if="!item.edit" :key="value">
        <slot :name="`slot_${value}`" :item="item" />
        <span :key="value" v-if="!$scopedSlots[`slot_${value}`]"
          >{{ item[value] }}
        </span>
      </div>
      <div v-else-if="item.edit" :key="value">
        <slot :name="`slot_${value}`" :item="item" />
        <div v-if="!$scopedSlots[`slot_${value}`]">
          <v-text-field
            v-if="edit"
            outlined
            class="ma-0 rounded-0"
            full-width
            hide-details
            dense
            :dark="true"
            background-color="#20212E"
            v-model="item[value]"
          >
          </v-text-field>
          <span :key="value" v-else>{{ item[value] }} </span>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["items", "headerTable"],
};
</script>

<style></style>
