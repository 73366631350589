<template>
  <v-row class="align-center justify-center">
    <v-col
      :cols="cols"
      class="pa-1"
      v-for="{
        text,
        value,
        rules,
        readonly,
        type,
        disabled,
        drowpdownValues,
      } in fields"
      :key="text"
    >
      <span
        v-if="type !== 'label'"
        class="text-subtitle-1 font-weight-bold pa-1 text-capitalize"
        :style="{ letterSpacing: '1.5rem' }"
        >{{ text }}</span
      >
      <v-tooltip
        bottom
        :disabled="!(errors && errors[text] && errors[text].length)"
        color="warning"
      >
        <template v-slot:activator="{ on }">
          <ValidationProvider
            :rules="rules"
            :name="text"
            v-slot="{ valid, dirty }"
          >
            <v-select
              v-if="type === 'dropdown'"
              v-model="data[value]"
              :items="drowpdownValues.values"
              :item-text="drowpdownValues.text ? drowpdownValues.text : ''"
              :item-value="drowpdownValues.value ? drowpdownValues.value : ''"
              single-line
              dense
              solo
              background-color="#20212E"
              hide-details
              class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
            >
            </v-select>
            <DatePicker
              v-model="data[value]"
              :data="data"
              :keyData="value"
              :existingModel="data[value]"
              v-else-if="type === 'DatePicker'"
            ></DatePicker>

            <div
              style="padding-top: 1rem !important; font-weight: 200 !important"
              :data="data"
              :keyData="value"
              v-else-if="type === 'label'"
            >
              Optional
              <v-divider inset></v-divider>
            </div>

            <v-text-field
              v-else
              :name="text"
              v-model="data[value]"
              class="ma-0 pt-1"
              type="text"
              outlined
              dense
              single-line
              hide-details
              :error="dirty && !valid"
              background-color="#20212E"
              :readonly="readonly ? readonly : false"
              :disabled="disabled ? disabled : false"
              v-on="on"
            >
            </v-text-field>
          </ValidationProvider>
        </template>
        <span class="font-weight-bold dark_blue--text">{{
          errors && errors[text] && errors[text].length
            ? errors[text] && errors[text][0]
            : ""
        }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import DatePicker from "@/components/General/DatePickerGeneral.vue";

export default {
  props: {
    fields: {
      type: Array,
    },
    data: {
      type: Object,
    },
    cols: {
      type: String,
    },
    errors: {
      type: Object,
    },
    dropdownValues: {
      type: Object,
    },
  },

  // ["fields", "data", "cols", "errors"],
  components: {
    ValidationProvider,
    DatePicker,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
