<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    app
    :mini-variant.sync="mini"
    :hide-overlay="true"
    :mobile-breakpoint="0"
  >
    <div class="row fill-height">
      <v-col>
        <v-list class="sb-2" dense color="primary">
          <v-list-item class="px-4">
            <v-list-item-content>
              <v-list-item-title>
                <h1 v-if="toggleMini" class="small-nav-letter">N</h1>
                <h3 v-if="!toggleMini" class="font-weight-heavy">
                  Nereus Asset Management
                </h3>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item class="px-2" @click="toggleMini = !toggleMini">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-list-item-avatar>
                    <v-icon>mdi-account-outline</v-icon>
                  </v-list-item-avatar>
                </span> </template
              ><span>{{ user.name }}</span>
            </v-tooltip>
            <v-list-item-content class="text-truncate">
              <v-list-item-title v-text="user.name"></v-list-item-title>
              <v-list-item-subtitle
                v-text="user.organization_descr"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-btn icon small>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :data-cy="item.dataCy"
            link
            :to="item.href"
            exact
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }} </v-icon>
                  </v-list-item-icon>
                </span> </template
              ><span>{{ item.title }}</span>
            </v-tooltip>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="item in itemsConfigs"
            :key="item.title"
            :data-cy="item.dataCy"
            link
            :to="item.href"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </span> </template
              ><span>{{ item.title }}</span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="item in itemsSettings"
            :key="item.title"
            :data-cy="item.dataCy"
            link
            :to="item.href"
            @click="item.method && $emit('showModal')"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </span> </template
              ><span>{{ item.title }}</span>
            </v-tooltip>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-list class="mt-auto">
        <v-list-item @click="logout">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-logout</v-icon>
                </v-list-item-icon>
              </span> </template
            ><span>Logout</span>
          </v-tooltip>
          <v-list-item-content>
            <v-list-item-title class="primary--text"> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>
.v-list {
  padding: 0px;
}
.sb-2 {
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 5px;
}
</style>

<script>
export default {
  name: "Sidebar2",
  props: ["user"],

  computed: {
    mini: {
      set: function (newValue) {
        return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
      },
      get: function () {
        return this.toggleMini;
      },
    },
    buttonText() {
      return !this.$vuetify.theme.dark ? "Go Dark" : "Go Light";
    },
  },
  data: () => ({
    showMe: true,
    sidebarMenu: true,
    toggleMini: true,
    items: [
      { title: "Home", href: "/", icon: "mdi-home-outline", dataCy: "sb-home" },
      {
        title: "Inventory",
        href: "/inventory",
        icon: "mdi-table-search",
        dataCy: "sb-inventory",
      },
      {
        title: "Upload",
        href: "/inventory/upload",
        icon: "mdi-upload",
        dataCy: "sb-inventory-upload",
      },

      {
        title: "Inventory Warnings",
        href: "/inventory/warnings",
        icon: "mdi-sync-alert",
        dataCy: "sb-inventory-warnings",
      },
    ],
    itemsConfigs: [
      {
        title: "Inventory Summary",
        href: "/inventory/qtysumm",
        icon: "mdi-summit",
        dataCy: "sb-inventory-qtysumm",
      },

      {
        title: "Order Management",
        href: "/orders",
        icon: "mdi-note-text",
        dataCy: "sb-orders",
      },

      // UAT DISABLED
      // {
      //   title: "Inventory Allocation",
      //   href: "/inventory/allocations",
      //   icon: "mdi-summit",
      // },
      // {
      //   title: "Buyer Parties",
      //   href: "/buyers",
      //   icon: "mdi-account-group",
      // },
      {
        title: "Status Codes",
        href: "/container/status",
        icon: "mdi-list-status",
        dataCy: "sb-container-status",
      },
      // {
      //   title: "Depot Terminations",
      //   href: "/depot/terminations",
      //   icon: "mdi-crosshairs-gps",
      // },

      {
        title: "Depots",
        href: "/depots",
        icon: "mdi-crosshairs-gps",
        dataCy: "sb-depots",
      },
      {
        title: "Organizations",
        href: "/organizations",
        icon: "mdi-domain",
        dataCy: "sb-organizations",
      },
      {
        title: "Reports",
        href: "/reports",
        icon: "mdi-file-multiple",
        dataCy: "sb-reports",
      },

      {
        title: "Auctions",
        href: "/auctions",
        icon: "mdi-gavel",
        dataCy: "sb-auctions",
      },
      {
        title: "Auctions Invites",
        href: "/auction/invites",
        icon: "mdi-email",
        dataCy: "sb-invites",
      },
      {
        title: "Customer Distribution List",
        href: "/customerlist",
        icon: "mdi-list-box",
      },
    ],
    itemsSettings: [
      {
        title: "Settings",
        href: "/settings",
        icon: "mdi-cog",
        dataCy: "sb-settings",
      },
      {
        method: true,
        title: "Invite customers",

        icon: "mdi-account-multiple-plus",
      },
    ],
  }),
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = true;
    },
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("header");
      this.$store.commit("set_user", {});
      this.$router.push("/login");
    },
  },
  mounted() {
    this.$vuetify.theme.dark = true;
  },
};
</script>
