<template>
  <input :value="value" @input="$emit('change', $event.target.value)" />
</template>

<script>
export default {
  props: ["value"],
  model: {
    prop: "value",
    event: "change",
  },
};
</script>

<style></style>
