<template>
  <v-card class="dark_blue" outlined>
    <v-row
      v-for="field in fields"
      :key="field.value"
      class="darker_blue pa-2 elevation-5 mt-1"
    >
      <slot :name="`title_${field.value}`" :item="obj[field.value]" />
      <v-col
        cols="5"
        class="font-weight-bold"
        v-if="!$scopedSlots[`title_${field.value}`]"
        >{{ field.text }}
      </v-col>
      <slot :name="`value_${field.value}`" :item="obj[field.value]" />

      <v-col
        class="font-weight-thin font-italic"
        v-if="!$scopedSlots[`value_${field.value}`]"
        >{{ obj[field.value] }}</v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["fields", "obj"],
  computed: {
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
    }),
    a() {
      return this.$scopedSlots;
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
