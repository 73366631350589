<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <ValidationProvider v-slot="{ errors, failedRules }" :rules="rules" :name="title">
        <v-text-field
          :outlined="!solo && !flat ? true : false"
          :solo="solo ? true : false"
          :flat="flat ? true : false"
          :class="classes"
          :full-width="notfull"
          :label="title"
          :hide-details="hideDetails || false"
          :error-messages="errors && errors[0]"
          :disabled="disabled"
          :light="light"
          :background-color="backgroundColor || '#20212E'"
          class="ma-0 rounded-0 text-field__calendar"
          dense
          :hint="format || 'YYYY-MM-DD format'"
          persistent-hint
          prepend-inner-icon="mdi-calendar"
          v-model="data[keyData]"
          v-bind="attrs"
          v-on="on"
          @click="showMenu"
          type="text"
          readonly
        >
        </v-text-field>
        <slot name="error" :errors="errors" :failedRules="failedRules"></slot>
      </ValidationProvider>
    </template>
    <v-date-picker
      v-model="data[keyData]"
      no-title
      @input="updateDate"
      class="date-picker"
      color="primary"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from "luxon";
import { ValidationProvider } from "vee-validate";
export default {
  props: [
    "data",
    "keyData",
    "disabled",
    "emitEvent",
    "solo",
    "clicked",
    "title",
    "existingModel",
    "hideDetails",
    "notfull",
    "toFormat",
    "rules",
    "title",
    "textfield_classes",
    "flat",
    "format",
    "light",
    "backgroundColor",
  ],
  components: {
    ValidationProvider,
  },
  data() {
    return {
      showMenu: false,
      newDate: this.existingModel,
    };
  },
  computed: {
    classes() {
      let clasess = "";
      clasess += this.disabled ? "input-disable" : "";
      clasess += this.solo ? "mt-1" : "";
      return `${clasess} ${this.textfield_classes}`;
    },
  },
  methods: {
    updateDate(val) {
      console.log(this.data[this.keyData]);
      const timeNowLocal = new DateTime.now().toFormat("HH-mm-ss");
      //   console.log("timeNowLocal", timeNowLocal);

      let date = DateTime.fromSQL(this.data[this.keyData], {});
      let dateLocalStr = `${date.toFormat("yyyy-LL-dd")} ${timeNowLocal}`;

      const dateLocalObj = new DateTime.fromFormat(dateLocalStr, "yyyy-LL-dd HH-mm-ss");

      //   console.log(
      //     "dateLocalObjStr",
      //     dateLocalObj.toFormat("yyyy-LL-dd HH-mm-ss"),
      //   );

      this.$set(
        this.data,
        this.keyData,
        dateLocalObj.toFormat(this.toFormat ? this.toFormat : "yyyy-LL-dd HH-mm-ss")
      );

      this.data[`clientDate_${this.keyData}`] = dateLocalObj.toFormat(
        this.toFormat ? this.toFormat : "yyyy-LL-dd HH-mm-ss"
      );

      date = dateLocalObj.setZone("America/Phoenix", {
        includeZone: false,
      });

      this.data[`serverDate_${this.keyData}`] = date.toFormat(
        this.toFormat ? this.toFormat : "yyyy-LL-dd HH-mm-ss"
      );

      //   console.log("dateServerObjStr", date.toFormat("yyyy-LL-dd HH-mm-ss"));
      if (this.emitEvent) if (this.emitEvent) this.$emit(`${this.emitEvent}`);

      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss">
.v-input input {
  cursor: default;
}
.input-disable {
  background-color: #2f2f2a;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0px !important;
}
</style>
