<template>
  <v-row :class="rowClass">
    <v-col
      class="col-ajust"
      v-for="({ title, disable }, index) in tabValues"
      :key="title"
    >
      <v-btn
        v-if="!disable"
        small
        :class="index === tab ? activeClass : customClass"
        dense
        block
        style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
        :disabled="disable !== undefined ? !disable : false"
        @click="$emit('changeTab', index)"
      >
        <span class="text-capitalize">
          {{ title }}
        </span>
      </v-btn>

      <v-btn
        v-if="disable"
        small
        :class="index === tab ? activeClass : customClass"
        dense
        block
        style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
        :disabled="true"
        @click="$emit('changeTab', index)"
      >
        <span class="text-capitalize">
          {{ title }}
        </span>
      </v-btn>
    </v-col>

    <slot> </slot>
  </v-row>
</template>

<script>
export default {
  props: ["tab", "tabValues", "classes", "btnclasses", "classActive"],
  data() {
    return {};
  },

  computed: {
    rowClass() {
      if (this.classes) return `pa-0 elevation-5 ${this.classes} `;
      else return "pa-0 elevation-5";
    },
    customClass() {
      if (this.btnclasses) return `${this.btnclasses} px-5 py-2 ma-0  text-lg-caption `;
      else
        return "px-5 py-2 ma-0 text-lg-caption font-weight-bold primary--text rounded-0";
    },
    activeClass() {
      if (this.classActive) return `${this.customClass} ${this.classActive}`;
      else return `${this.customClass} primary dark_blue--text bold-text`;
    },
  },
};
</script>

<style></style>
