<template>
  <span class="d-flex">{{ title }}</span>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
span:before,
span:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
span:before {
  margin-right: 10px;
}
span:after {
  margin-left: 10px;
}
</style>
