<template>
  <div id="snackbar">
    <!-- <v-btn
      class="primary"
      @click="hideAllSnackbar"
      v-if="snackbars && snackbars.length > 1"
      >Close All</v-btn
    > -->

    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      v-model="snackbar.show"
      @input="updateSnackbarValue"
      absolute
      top
      right
      class="ma-0 pa-0"
      :color="snackbar.color"
      outlined
      :key="index"
      dense
      :timeout="snackbar.timeout || '5000'"
      background-color="white"
    >
      <span style="font-weight: 800; width: 100%">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" dense @click="hideSnackbar(index)"
          ><v-icon dark>mdi-close</v-icon></v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss">
#snackbar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999999;
  display: flex;
  /* width: 100vw; */
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: space-around;
  div {
    position: relative !important;
    // margin-bottom: 8px;
  }
}
</style>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: ["nav"],
  computed: {
    ...mapState(["snackbar", "snackbars"]),
    showVar() {
      return this.nav ? "showNav" : "showLog";
    },
  },
  data() {
    return {
      defaultValue: [
        {
          text: "",
          color: "",
          showNav: false,
          showLog: false,
          timeout: -1,
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["closeSnackbar", "hideSnackbar", "hideAllSnackbar"]),
    updateSnackbarValue(value) {
      this.closeSnackbar(this.defaultValue);
    },
  },
};
</script>

<style></style>
